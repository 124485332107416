






























import { computed, defineComponent } from '@vue/composition-api';
import AvatarDefaultImage from '@/assets/avatar.png';
import { useMainStore } from '@/store';
// import { useWindowSize } from '@u3u/vue-hooks';

export default defineComponent({
  setup(_, { root }) {
    const main = useMainStore();
    // const { width } = useWindowSize();

    return {
      // navRight: computed(() => Math.max(0, width.value / 2 - 630) + 'px'),
      avatar: computed(() =>
        main.state.user && main.state.user.avatar ? main.state.user.avatar : AvatarDefaultImage,
      ),
      navItems: [
        { label: '个人资料', icon: 'el-icon-user', name: 'UserInfo' },
        { label: '服务进度', icon: 'el-icon-s-order', name: 'UserInfo' },
        { label: '我的收藏', icon: 'el-icon-star-off', name: 'UserInfo' },
        { label: '活动历程', icon: 'el-icon-date', name: 'UserInfo' },
        {
          label: '退出登录',
          icon: 'el-icon-switch-button',
          onClick: async () => {
            await main.logout();
            if (root.$route.name !== 'Home') {
              root.$router.push({ name: 'Home' });
            }
          },
        },
      ],
    };
  },
});
