export default `中药材的种植
兽医服务
营养、保健食品制造
其他未列明的食品制造
茶饮料及其他软饮料制造
化学药品原药制造
化学药品制剂制造
中药饮片加工
中成药制造
兽用药品制造
生物、生化制品的制造
卫生材料及医药用品制造
制药专用设备制造
基础软件服务
应用软件服务
其他软件服务
西药批发
中药材及中成药批发
医疗用品及器材批发
药品零售
医疗用品及器材零售
综合医院
中医医院
中西医结合医院
民族医院
专科医院
疗养院
卫生院及社区医疗活动
门诊部医疗活动
计划生育技术服务活动
妇幼保健活动
专科疾病防治活动
疾病预防控制及防疫活动
其他卫生活动`.split('\n');
