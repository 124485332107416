























































































import { useMainStore } from '@/store';
import { computed, defineComponent, ref, toRef } from '@vue/composition-api';
import { Message } from 'element-ui';
import Tags from './TagOptions';

export default defineComponent({
  props: { visible: { type: Boolean, default: false } },
  setup(_, { emit }) {
    const username = ref('');
    const password = ref('');
    const captcha = ref('');
    const error = ref<string>();
    const type = ref('login');
    const loading = ref(false);
    const cooldown = ref(0);
    const repeatPassword = ref('');

    const {
      state,
      login,
      requestRegisterCaptcha,
      register,
      requestForgetCaptcha,
      resetPassword,
    } = useMainStore();
    const close = (v = false) => {
      emit('update:visible', v);
    };

    const startCooldown = (val: number) => {
      cooldown.value = val;
      if (cooldown.value > 0) {
        setTimeout(() => startCooldown(val - 1), 1000);
      }
    };

    return {
      tags: Tags,
      selectedTags: ref([]),
      useSuggest: ref(false),
      username,
      password,
      captcha,
      type,
      loading,
      cooldown,
      repeatPassword,
      canNext: computed(() => {
        if (type.value === 'register' || type.value === 'forget') {
          return username.value && password.value && captcha.value && repeatPassword.value;
        }

        if (type.value === 'login') {
          return username.value && password.value;
        }

        return false;
      }),
      error: computed(() => error.value || state.loginError),
      onLogin: async () => {
        error.value = undefined;
        if (username.value.length < 4) {
          error.value = '用户名需要大于 3 个字符';
          return;
        }
        if (password.value.length < 6) {
          error.value = '密码需要大于 5 个字符';
          return;
        }

        const successed = await login(username.value, password.value);
        if (successed) {
          close();
        }
      },
      onRegister: async () => {
        error.value = '';
        try {
          if (
            type.value === 'register' &&
            username.value &&
            password.value &&
            captcha.value &&
            repeatPassword.value
          ) {
            if (password.value === repeatPassword.value) {
              const successed = await register(username.value, password.value, captcha.value);
              if (successed) {
                Message.success('注册成功');
                close();
              }
            } else {
              error.value = '两次输入的密码不一致';
            }
          }
        } catch (error) {
          const err = error;
          debugger;
          error.value = '注册失败';
        }
      },
      onReset: async () => {
        error.value = '';
        try {
          if (
            type.value === 'forget' &&
            username.value &&
            password.value &&
            captcha.value &&
            repeatPassword.value
          ) {
            if (password.value === repeatPassword.value) {
              const successed = await resetPassword(username.value, password.value, captcha.value);
              if (successed) {
                Message.success('重置密码成功');
                type.value = 'login';
              } else {
                error.value = '验证码错误';
              }
            } else {
              error.value = '两次输入的密码不一致';
            }
          }
        } catch (error) {
          error.value = error.message || '重置密码失败';
        }
      },
      getCaptcha: async () => {
        error.value = '';
        if (username.value) {
          if (
            (type.value === 'register' || type.value === 'forget') &&
            username.value &&
            cooldown.value === 0
          ) {
            if (/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(username.value)) {
              try {
                if (type.value === 'register') {
                  await requestRegisterCaptcha(username.value);
                } else {
                  await requestForgetCaptcha(username.value);
                }
                startCooldown(60);
                Message.success('已发送验证码');
              } catch (error) {
                Message.error('获取验证码失败');
              }
            } else {
              error.value = '请输入正确的手机号码';
            }
          }
        } else {
          error.value = '请输入手机号码';
        }
      },
      close,
    };
  },
});
