import { request } from '@/util/request';
import { Article } from './model';

export * from './model';

export function getNews(option: { page: number; size: number }) {
  return request<Article[]>('/strapi/articles/', {
    params: {
      type: 'news',
      _start: (option.page - 1) * option.size,
      _limit: option.size,
    },
  });
}

export function getNewsDetail(id: number) {
  return request<Article>(`/strapi/articles/${id}`, {});
}

export function getNewsCount() {
  return request<Article[]>('/strapi/articles/count', {
    params: {
      type: 'news',
    },
  });
}
