























































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  setup(_, { root }) {
    return {
      navTo(name: string) {
        root.$router.push({ name });
      },
    };
  },
});
