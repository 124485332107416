import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import './element-variables.scss';
import VueCompositionApi from '@vue/composition-api';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
// import hooks from '@u3u/vue-hooks';
import Card from '@/components/Card.vue';
import Recommend from '@/components/Recommend.vue';
import { bottomDirective } from './util/bottomDirective';
import './share.css';

// Vue.use(hooks);
dayjs.locale('zh-cn');
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueCompositionApi);
Vue.directive('bottom', bottomDirective);
Vue.filter('date', (val: string) => {
  return dayjs(val).format('YYYY-MM-DD');
});

Vue.component('Card', Card);
Vue.component('Recommend', Recommend);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
