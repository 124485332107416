import { request } from '@/util/request';
import { CurrentUser, LoginResult, NavModule, Hospital } from './model';

export function getModules() {
  return request<NavModule[]>('/strapi/modules', { params: { _sort: 'sort:ASC' } });
}

export function getHospital() {
  return request<Hospital[]>('/strapi/hospitals');
}

export async function registryUser(data: { username: string; password: string }) {
  return request<LoginResult>('/api/user/register', {
    method: 'post',
    data,
  });
}

export async function queryCurrentUser() {
  return request<CurrentUser>('/strapi/users/me');
}

export async function login({ username, password }: { username: string; password: string }) {
  return request<LoginResult>('/api/user/login', {
    method: 'post',
    data: { phoneNumber: username, password },
  });
}

export async function getCaptcha({ username }: { username: string }) {
  return request<LoginResult>('/api/verfication/sms', {
    method: 'post',
    data: { phoneNumber: username },
  });
}

export async function getCaptchaForgetPassword({ username }: { username: string }) {
  return request<LoginResult>('/api/forget/send', {
    method: 'post',
    data: { phoneNumber: username },
  });
}

export async function resetPassword({
  username,
  password,
  code,
}: {
  username: string;
  password: string;
  code: string;
}) {
  return request<LoginResult & { message?: string }>('/api/forget/reset', {
    method: 'post',
    data: { phoneNumber: username, password, code },
  });
}

export async function register({
  username,
  password,
  code,
}: {
  username: string;
  password: string;
  code: string;
}) {
  return request<LoginResult & { message?: string }>('/api/user/register', {
    method: 'post',
    data: { phoneNumber: username, password, code },
  });
}
