

























































import { computed, defineComponent } from '@vue/composition-api';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import type { SwiperOptions } from 'swiper';
import 'swiper/css/swiper.css';
import { useMainStore } from '@/store';

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(_, { root }) {
    const swiperOption: SwiperOptions = {
      slidesPerView: 3,
      spaceBetween: 30,
      // autoplay:true,
      // loop: true,
    };

    const { state } = useMainStore();

    const navTo = (id: number) => {
      root.$router.push({ name: 'HospitalDetail', params: { id: id.toString() } });
    };

    return {
      swiperOption,
      navTo,
      hospitals: computed(() => state.hospitals || []),
      demands: computed(() => state.hospitalDemand),
      patents: computed(() => state.hospitalPatentCount),
    };
  },
});
