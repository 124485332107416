

























import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api';
import { useApi } from '@/util/useApi';
import { getStatistics } from './api';
import CountTo from 'vue-count-to';

export default defineComponent({
  components: { CountTo },
  props: {
    title: { type: String },
  },
  setup(_, { refs }) {
    const { result } = useApi(() => getStatistics());

    const renderKey = ref(false);
    const io = new IntersectionObserver(entries => {
      entries.forEach(e => {
        if (e.intersectionRatio > 0) {
          renderKey.value = true;
        }
      });
    });

    onUnmounted(() => {
      io.disconnect();
    });

    onMounted(() => {
      if (refs && refs.el) {
        if (window.IntersectionObserver) {
          const el = refs.el as HTMLDivElement;
          io.observe(el);
        } else {
          renderKey.value = true;
        }
      }
    });

    return { result, renderKey };
  },
});
