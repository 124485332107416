














import { computed, defineComponent, onMounted, watch } from '@vue/composition-api';
import { useDemandStore } from '@/views/Demand/store';
import { useApi } from '@/util/useApi';
import { getDemands, Demand } from '@/views/Demand/api';
import VClamp from 'vue-clamp';

export default defineComponent({
  components: { VClamp },
  setup(_, { root }) {
    const { setCurrent, state } = useDemandStore();

    onMounted(async () => {
      if (state.cache.length === 0) {
        const res = await getDemands({ page: 1, size: 5 });
        if (res && res.data) {
          state.cache = res.data;
        }
      }
    });

    return {
      demands: computed(() => state.cache),
      toDetail(item: Demand) {
        setCurrent(item);
        root.$router.push({ name: 'DemandDetail', params: { id: item.no } });
      },
    };
  },
});
