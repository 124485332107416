












import { defineComponent, onMounted } from '@vue/composition-api';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { useMainStore } from '@/store';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

export default defineComponent({
  components: { Header, Footer },
  setup() {
    const { init } = useMainStore();
    onMounted(() => {
      init();
      window.simpleBar = new SimpleBar(document.getElementById('layout-warper')!);
    });
    return {};
  },
});
