














































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useApi } from '@/util/useApi';
import { useMainStore } from '@/store';
import Card from '@/components/Card.vue';
import Divider from '@/components/Divider.vue';
import { getResults, Result } from '@/views/Result/api';
import VClamp from 'vue-clamp';
import Statistics from './Statistics.vue';
import { NavModule } from '@/store/api/model';
import router from '@/router';
import { useDemandStore } from '../Demand/store';
import { useResultStore } from '../Result/store';
import { getNews } from '@/views/Article/api';
import Hospital from './Hospital.vue';
import NewsCard from '@/components/NewsCard.vue';
import ResultCard from '@/components/ResultCard.vue';
import Banner from './Banner.vue';
import Search from '@/components/Search.vue';


export default defineComponent({
  components: { Card, Divider, VClamp, Statistics, Hospital, NewsCard, ResultCard, Banner, Search },
  setup() {
    const main = useMainStore();
    main.init();
    // const { result: modules } = useApi(() => getHomeModules(), { data: [[], []] });
    const { result: results } = useApi(() => getResults({ page: 1, size: 6 }));
    const { result: news } = useApi(() => getNews({ page: 1, size: 6 }));
    // const { result: tags, refresh: refreshTags } = useApi(() => getTags());
    const demand = useDemandStore();
    const resultStore = useResultStore();

    const showRecommend = ref(localStorage.getItem('showRecommend') !== 'false');

    // watch(() => main.state.user, refreshTags);

    return {
      showRecommend,
      closeRecommend:()=>{
        showRecommend.value = false;
        localStorage.setItem('showRecommend', 'false');
      },
      keyword: '',
      // tags: computed(() => (tags.value ? tags.value.split(',') : ['口罩', '疫情防护'])),
      top: computed(() => main.functionModules),
      bottom: computed(() => {
        const root = main.state.modules.filter(m => m.parent_id === null);
        if (root.length === 2) {
          return main.state.modules
            .filter(m => m.parent_id === root[1].id.toString())
            .map(m => ({ ...m, icon: main.state.oss + m.icon }));
        }
        return [];
      }),
      results: computed(() => results.value?.data.result.slice(0, 6) || []),
      cooperationUnit: computed(
        () =>
          main.state.config?.cooperationUnit
            ?.slice(0, 3 * 6)
            .map(u => ({ image: main.state.oss + u.image })) || [],
      ),
      news: computed(
        () =>
          news.value?.slice(0, 6).map(n => ({ ...n, thumbnail: main.state.oss + n.thumbnail })) ||
          [],
      ),

      toContentPage: (nav: NavModule) => {
        if (nav.url) {
          if (nav.url === '../moduleTips/index') {
            router.push({ name: 'Toolkit' });
          } else if (nav.url === '../resultDemand/index') {
            demand.page = 1;
            router.push({ name: 'Demand' });
          } else if (nav.url === '../research/index') {
            router.push({ name: 'PatentSubscribe' });
          }
        } else if (nav.id && nav.form_id) {
          if (nav.isNeedLogin && !main.state.user) {
            main.state.showLogin = true;
            return;
          }
          router.push({ name: 'ContentPage', params: { id: nav.id.toString() } });
        }
      },
      toResultDetail: (item: Result) => {
        resultStore.setCurrent(item);
        router.push({ name: 'ResultDetail' });
      },
    };
  },
});
