



































import { defineComponent, PropType, ref } from '@vue/composition-api';
import VClamp from 'vue-clamp';
import { Result } from '@/views/Result/api';
import { useResultStore } from '@/views/Result/store';
import RandomImage from './RandomImage.vue';
import RequiredContact from './RequiredContact.vue';
export default defineComponent({
  components: { VClamp, RandomImage, RequiredContact },
  props: {
    result: {
      type: Object as PropType<Result>,
      required: true,
    },
  },
  emits: ['required'],
  setup(props, { root }) {
    const resultStore = useResultStore();
    const showRequiredDialog = ref(false);

    return {
      showRequiredDialog,
      toResultDetail: (item: Result) => {
        resultStore.setCurrent(item);
        root.$router.push({ name: 'ResultDetail' });
      },
    };
  },
});
