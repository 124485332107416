import { request } from '@/util/request';
import { ResultResponse } from './model';

export * from './model';

export function getResults(option: { page: number; size: number }) {
  return request<{ data: ResultResponse }>('/api/selectPatentResultServiceListByPage', {
    params: option,
    method: 'POST',
  });
}
