




















































































import { useMainStore } from '@/store';
import LoginDialog from './Login.vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import Card from './Card.vue';
import Avatar from './Avatar.vue';
import { routes } from '@/router';
import Search from './Search.vue';
import Services from './Services.vue';

export default defineComponent({
  components: { Card, LoginDialog, Avatar, Search, Services },
  setup(_, { root }) {
    const main = useMainStore();
    const toolkit = computed(() => main.toolkitModules);
    const hoverItem = ref();
    return {
      toolkit,
      hoverItem,
      isShowLogin: computed(() => main.state.showLogin),
      setIsShowLogin: (v: boolean) => (main.state.showLogin = v),
      state: main.state,
      backBreadcrumb: computed(() =>
        root.$route.meta?.back
          ? routes[0].children?.find(r => r.name === root.$route.meta?.back)
          : undefined,
      ),
      toToolkit: (id: string) => {
        if (root.$route.name !== 'Toolkit' || root.$route.params.id !== id) {
          root.$router.push({ name: 'Toolkit', params: { id } });
          hoverItem.value = undefined;
        }
      },
      to: (name: string, params?: Record<string, never>) => {
        if (root.$route.name !== name) {
          if (!['Contact', 'Toolkit', 'Services'].includes(name)) {
            root.$router.push({ name, params });
          }
        }
      },
      links: [
        {
          name: 'Home',
          label: '首页',
        },
        {
          name: 'About',
          label: '关于我们',
        },
        {
          name: 'Services',
          label: '医企服务',
        },
        // {
        //   name: 'Result',
        //   label: '医学成果库',
        // },
        {
          name: 'Rank',
          label: 'PMIT排行',
        },
        {
          name: 'News',
          label: '精彩案例',
        },
        {
          name: 'Meeting',
          label: '会议活动',
        },
        // {
        //   name: 'Toolkit',
        //   label: '成果转化工具',
        // },
        {
          name: 'Study',
          label: '新闻资讯',
        },

        // {
        //   name: 'Financing',
        //   label: '我要融资',
        // },
        {
          name: 'Contact',
          label: '联系我们',
        },
      ],
    };
  },
});
