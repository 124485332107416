




























import { defineComponent, PropType } from '@vue/composition-api';
import { Article } from '@/views/Article/api/model';
import VClamp from 'vue-clamp';
export default defineComponent({
  components: { VClamp },
  props: {
    article: {
      type: Object as PropType<Article>,
      required: true,
    },
  },
  setup(props, { root }) {
    return {
      toDetail: (id: number) => {
        root.$router.push({ name: 'NewsDetail', params: { id: id.toString() } });
      },
    };
  },
});
