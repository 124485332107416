import { createStore } from 'pinia';
import { Result } from './api';

export const useResultStore = createStore({
  id: 'result',
  state: () => ({
    current: null as null | Result,
  }),
  // optional getters
  actions: {
    setCurrent(current: null | Result) {
      this.current = current;
    },
  },
});
