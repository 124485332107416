import { request } from '@/util/request';
import { Demand } from './model';

export * from './model';

export async function getDemands({
  page,
  size,
  hospital,
}: {
  page: number;
  size: number;
  hospital?: string;
}) {
  const res = await request<Demand[]>('/strapi/result-demands', { params: { hospital } });
  return { data: res.slice((page - 1) * size, page * size), total: res.length };
}

export async function getDemandDetail(id: number) {
  const res = await request<Demand>('/strapi/result-demands/' + id);
  return res;
}
