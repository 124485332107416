






















import { useMainStore } from '@/store';
import { computed, defineComponent, reactive } from '@vue/composition-api';
import { Message } from 'element-ui';

export default defineComponent({
  props: {
    header: {
      type: Boolean,
      default: true,
    },
  },
  setup(_, { root }) {
    const main = useMainStore();

    const formValue = reactive({
      phone: main.user?.username,
      name: main.user?.nickname,
    });

    const onRequired = async () => {
      try {
        if (main.state.user) {
          Message.success('提交成功');
        } else {
          main.state.showLogin = true;
        }
      } catch {
        //
      }
    };

    return {
      formValue,
      onRequired,
    };
  },
});
