













import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    icon: { type: String },
    title: { type: String },
    size: { type: Number, default: 28 },
    hover: { type: Boolean, default: true },
  },
  setup() {
    return {};
  },
});
