import { createStore } from 'pinia';
import { Demand } from './api';

export const useDemandStore = createStore({
  id: 'demand',
  state: () => ({
    current: null as null | Demand,
    page: 1,
    cache: [] as Demand[],
  }),
  // optional getters
  actions: {
    setCurrent(current: null | Demand) {
      this.current = current;
    },
  },
});
