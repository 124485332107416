









import { computed, defineComponent } from '@vue/composition-api';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import type { SwiperOptions } from 'swiper';
import 'swiper/css/swiper.css';
import { useApi } from '@/util/useApi';
import { getBanners } from './api';
import { useMainStore } from '@/store';
export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const main = useMainStore();

    const swiperOption: SwiperOptions = {
      autoplay:true,
      loop: true,
       pagination: {
        el: '.swiper-pagination'
      }
    };
    return {
      swiperOption,
      banners: computed(() =>  main.banners  ),
    };
  },
});
