import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Layout from '@/components/Layout.vue';
import Home from '../views/Home/index.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { top: 'Home', title: '首页', hiddenBreadcrumb: true },
      },
      {
        path: 'toolkit/:id',
        name: 'Toolkit',
        component: () => import('../views/Toolkit/index.vue'),
        meta: { top: 'Services', title: '医企服务' },
      },
      {
        path: 'rank',
        name: 'Rank',
        component: () => import('../views/Rank/index.vue'),
        meta: { top: 'Rank', title: 'PMIT排行' },
      },
      {
        path: 'financing',
        name: 'Financing',
        redirect: 'toolkit/29',
        meta: { top: 'Toolkit', title: '转化工具' },
      },
      {
        path: 'study',
        name: 'Study',
        component: () => import('../views/Study/index.vue'),
        meta: { top: 'Study', title: '新闻咨询' },
      },
      {
        path: 'study-video/:id',
        name: 'StudyVideo',
        component: () => import('../views/Study/VideoDetail.vue'),
        meta: { top: 'Study', title: '即时学习', back: 'Study' },
      },
      {
        path: 'study-resource/:id',
        name: 'StudyResource',
        component: () => import('../views/Study/ResourceDetail.vue'),
        meta: { top: 'Study', title: '学习资源', back: 'Study' },
      },
      {
        path: 'study-event/:id',
        name: 'StudyEvent',
        component: () => import('../views/Study/EventDetail.vue'),
        meta: { top: 'Study', title: '活动', back: 'Study' },
      },
      {
        path: 'patent',
        name: 'Patent',
        component: () => import('../views/Patent/index.vue'),
        meta: { top: 'Home', title: '专利搜索' },
      },
      {
        path: 'patent-detail',
        name: 'PatentDetail',
        component: () => import('../views/Patent/Detail.vue'),
        meta: { top: 'Home', title: '详情', back: 'Patent' },
      },
      {
        path: 'user-info',
        name: 'UserInfo',
        component: () => import('../views/User/index.vue'),
        meta: { top: 'Home', title: '个人资料' },
      },
      {
        path: 'result',
        name: 'Result',
        component: () => import('../views/Result/index.vue'),
        meta: { top: 'Result', title: '医学成果库' },
      },
      {
        path: 'result-detail',
        name: 'ResultDetail',
        component: () => import('../views/Result/Detail.vue'),
        meta: { top: 'Home', title: '详情', back: 'Result' },
      },
      {
        path: 'content/:id',
        name: 'ContentPage',
        component: () => import('../views/Content/index.vue'),
        meta: { top: '', title: '详情' },
      },
      {
        path: 'demand',
        name: 'Demand',
        component: () => import('../views/Demand/index.vue'),
        meta: { top: '', title: '成果转化需求' },
      },
      {
        path: 'demand-detail/:id',
        name: 'DemandDetail',
        component: () => import('../views/Demand/Detail.vue'),
        meta: { top: '', title: '详情', back: 'Demand' },
      },
      {
        path: 'news',
        name: 'News',
        component: () => import('../views/Article/index.vue'),
        meta: { top: 'News', title: '精彩案例' },
      },
      {
        path: 'news/:id',
        name: 'NewsDetail',
        component: () => import('../views/Article/Detail.vue'),
        meta: { top: '', title: '精彩案例', back: 'NewsDetail' },
      },
      {
        path: 'patentSubscribe',
        name: 'PatentSubscribe',
        component: () => import('../views/PatentSubscribe/index.vue'),
        meta: { top: '', title: '专利缴费提醒' },
      },
      {
        path: 'patentSubscribeDetail',
        name: 'PatentSubscribeDetail',
        component: () => import('../views/PatentSubscribe/Detail.vue'),
        meta: { top: '', title: '详情', back: '专利缴费提醒' },
      },
      {
        path: 'hospitalDetail/:id',
        name: 'HospitalDetail',
        component: () => import('../views/HospitalDetail/index.vue'),
        meta: { top: 'Home', title: '医院详情', back: '' },
      },
      {
        path: '/meeting',
        name: 'Meeting',
        component: () => import('../views/Study/Meeting.vue'),
        meta: { top: 'Meeting', title: '会议活动', back: '' },
      },
      {
        path: 'meeting/:id',
        name: 'MeetingDetail',
        component: () => import('../views/Study/ResourceDetail.vue'),
        meta: { top: 'Meeting', title: '详情', back: 'Meeting' },
      },
      {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: { top: 'About', title: '关于我们', back: '', hiddenBreadcrumb: true },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.simpleBar?.getScrollElement().scrollTo({ top: 0 });
    return { x: 0, y: 0 };
  },
});

export { routes };

export default router;
