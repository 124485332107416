import { inject, provide, InjectionKey } from '@vue/composition-api';
import StaticAxios, { AxiosInstance, AxiosRequestConfig, AxiosPromise } from 'axios';

export const AxiosSymbol = Symbol() as InjectionKey<AxiosInstance>;

export function useConfigure(options: { axios?: AxiosInstance } = {}) {
  if (options.axios !== undefined) {
    provide(AxiosSymbol, options.axios);
  }
}

export async function request<T>(url: string, config: AxiosRequestConfig = { method: 'GET' }) {
  const instance = StaticAxios.create({ baseURL: 'https://web.sk.yjhmittc.com/' });

  instance.interceptors.response.use(response => {
    if (response.status === 401) {
      localStorage.setItem('token', '');
      window.location.reload();
    }
    return response;
  });

  instance.interceptors.request.use(config => {
    const token = localStorage.getItem('token');

    const headers = config.headers ?? ({} as Record<string, any>);
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    config.headers = headers;
    config.headers.Accept = '*/*';
    return config;
  });
  try {
    const res = await instance(url, config);
    return res.data as T;
  } catch (error) {
    if (error.response.data) {
      throw error.response.data;
    }
    throw error;
  }
}
