import { NavModule } from '@/store/api/model';
import { request } from '@/util/request';
import { Banner, Article } from './model';

export function getBanners() {
  return request<Banner[]>('/strapi/banners', { params: { type: 'desktop' } });
}

export function getHomeModules() {
  return request<{ data: [NavModule[], NavModule[]] }>('/strapi/modules/home');
}

export function getStatistics() {
  return request<{ data: [NavModule[], NavModule[]] }>('/strapi/info/service_statistics');
}

export function getTags() {
  return request<string>('/strapi/user/tags');
}
