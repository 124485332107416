import { request } from '@/util/request';
import { getBanners } from '@/views/Home/api';
import { createStore } from 'pinia';
import {
  getCaptcha,
  getCaptchaForgetPassword,
  getModules,
  login,
  queryCurrentUser,
  register,
  resetPassword,
} from './api';
import { Config, CurrentUser, NavModule, Hospital } from './api/model';
import { arrayToTree } from 'performant-array-to-tree';
import { getHospital } from './api/index';
import { getDemands } from '@/views/Demand/api';
import { Demand } from '../views/Demand/api/model';
import { Patent } from '../views/Patent/api/model';
import { getPatents } from '@/views/Patent/api';
import { set } from '@vue/composition-api';

export const useMainStore = createStore({
  id: 'main',
  state: () => ({
    oss: '',
    modules: [] as NavModule[],
    user: null as CurrentUser | null,
    jwt: null as string | null,
    loginError: null as string | null,
    showLogin: false,
    initialized: false,
    banners: [] as Array<{ image: string; id: number; url?: string }>,
    config: {} as Config,
    hospitals: [] as Hospital[],
    hospitalDemand: {} as { [key: number]: Demand[] },
    hospitalPatentCount: {} as { [key: number]: number },
  }),
  getters: {
    toolkitModules() {
      const toolkit = this.modules.find(m => m.name === '转化工具包');
      if (toolkit && this.oss) {
        return this.modules
          .filter(m => m.parent_id === toolkit.id.toString())
          .map(m => ({
            ...m,
            children: this.modules
              .filter(m2 => m2.parent_id === m.id.toString())
              .map(m2 => ({ ...m2, icon: this.oss + m2.icon })),
          }));
      }
      return [];
    },
    servicesModules() {
      const services = this.modules.find(m => m.name === '医企服务');

      if (services && this.oss) {
        return this.modules
          .filter(m => m.parent_id === services.id.toString())
          .map(m => ({
            ...m,
            children: this.modules
              .filter(m2 => m2.parent_id === m.id.toString())
              .map(m2 => ({
                ...m2,
                icon: this.oss + m2.icon,
                children: this.modules
                  .filter(m3 => m3.parent_id === m2.id.toString())
                  .map(m3 => ({ ...m3, icon: this.oss + m3.icon, children: [] })),
              })),
          }));
      }
      return [];
    },
    functionModules() {
      const root = this.modules.filter(m => m.parent_id === null);
      if (root.length === 2) {
        return this.modules
          .filter(m => m.parent_id === root[0].id.toString())
          .map(m => ({ ...m, icon: this.oss + m.icon }));
      }
      return [];
    },
  },
  // optional getters
  actions: {
    async init() {
      if (this.initialized) {
        return;
      }
      this.initialized = true;
      // `this` is the store instance
      try {
        const ossRes = await request<Config>('/strapi/config');
        if (ossRes && ossRes.ossEndpoint) {
          this.config = ossRes;
          this.oss = ossRes.ossEndpoint + '/';
        } else {
          throw Error('获取 oss 失败');
        }

        const modulesRes = await getModules();
        if (modulesRes) {
          this.modules = modulesRes;
        } else {
          throw Error('获取 Modules 失败');
        }
        await this.updateUser();

        const banners = await getBanners();
        this.banners = banners.map(banner => ({
          image: this.state.oss + banner.image,
          id: banner.id,
          url: banner.url,
        }));

        const hospitals = await getHospital();
        this.hospitals = hospitals.map(hospital => ({
          ...hospital,
          icon: this.state.oss + hospital.icon,
          backgroundImage: this.state.oss + hospital.backgroundImage,
        }));

        this.hospitals.forEach(hospital => {
          getDemands({ page: 1, size: 99, hospital: hospital.searchKey || hospital.name }).then(
            res => {
              set(this.hospitalDemand, hospital.id, res.data);
            },
          );

          getPatents({ page: 1, size: 1, keyword: hospital.searchKey || hospital.name }).then(
            res => {
              set(this.hospitalPatentCount, hospital.id, res.data.total);
            },
          );
        });
      } catch (e) {
        console.error('初始化失败', e.message);
      }
    },
    async updateUser() {
      const token = localStorage.getItem('token');
      if (token) {
        const res = await queryCurrentUser();
        if (res && res.id) {
          this.user = res;
          this.user.avatar = res.avatar ? this.oss + this.user.avatar : '';
          this.jwt = token;
        }
      }
    },
    async login(username: string, password: string) {
      try {
        const res = await login({ username, password });
        if (res.jwt && res.user) {
          this.loginError = null;
          this.jwt = res.jwt;
          this.user = res.user;
          localStorage.setItem('token', res.jwt);
          return true;
        } else {
          this.jwt = null;
          this.user = null;
          this.loginError = '登录失败';
          return false;
        }
      } catch (e) {
        this.jwt = null;
        this.user = null;
        this.loginError = '账号或密码错误';
        return false;
      }
    },
    async register(username: string, password: string, code: string) {
      try {
        const res = await register({ username, password, code });
        if (res.jwt && res.user) {
          this.loginError = null;
          this.jwt = res.jwt;
          this.user = res.user;
          localStorage.setItem('token', res.jwt);
          return true;
        } else {
          this.jwt = null;
          this.user = null;
          this.loginError = res.message || '注册失败';
          return false;
        }
      } catch (e) {
        this.jwt = null;
        this.user = null;
        this.loginError = e.message || '注册失败';
        return false;
      }
    },
    async resetPassword(username: string, password: string, code: string) {
      try {
        await resetPassword({ username, password, code });
        return true;
      } catch (e) {
        return false;
      }
    },
    async logout() {
      this.jwt = null;
      this.user = null;
      this.loginError = null;
      localStorage.removeItem('token');
    },
    async requestRegisterCaptcha(username: string) {
      if (username) {
        await getCaptcha({ username });
      }
    },
    async requestForgetCaptcha(username: string) {
      if (username) {
        await getCaptchaForgetPassword({ username });
      }
    },
  },
});
