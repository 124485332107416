import { request } from '@/util/request';
import { PatentResponse, Patent, PatentPdfUrlResponse } from './model';
import { Collect } from '../../User/api';

export * from './model';

export function getPatents(option: {
  page: number;
  size: number;
  keyword?: string;
  type?: string;
  minApplyDate?: string;
  maxApplyDate?: string;
}) {
  if (option.type === '全部') {
    option.type = undefined;
  }
  return request<{ data: PatentResponse }>('/api/selectPatentListByPage', {
    params: option,
    method: 'POST',
  });
}

export function addToCollect(type: 'patent', content: any, content_id: string) {
  return request<Collect>('/strapi/collect', {
    method: 'POST',
    data: {
      type,
      content,
      content_id,
    },
  });
}

export function subscribePatent(target: Patent) {
  return request(`/strapi/patent/subscribes`, {
    method: 'POST',
    data: {
      target,
    },
  });
}
export function removeCollect(id: number) {
  return request(`/strapi/collects/${id}`, {
    method: 'DELETE',
  });
}

export function getPatentPdfUrl(sn: string) {
  const data = new FormData();
  data.append('patentSn', sn);
  return request<PatentPdfUrlResponse>(`/api/getShortPdfUrl`, {
    data,
    method: 'POST',
  });
}
