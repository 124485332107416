




import { computed, defineComponent } from '@vue/composition-api';
import Image1 from '@/assets/images/1.png';
import Image2 from '@/assets/images/2.png';
import Image3 from '@/assets/images/3.png';
import Image4 from '@/assets/images/4.png';
import Image5 from '@/assets/images/5.png';
import Image6 from '@/assets/images/6.png';
import Image7 from '@/assets/images/7.png';
import Image8 from '@/assets/images/8.png';
import Image9 from '@/assets/images/9.png';
import Image10 from '@/assets/images/10.png';

const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10];

export default defineComponent({
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  setup: props => {
    return {
      img: computed(() => images[props.index % images.length]),
    };
  },
});
