











































import { useMainStore } from '@/store';
import { useApi } from '@/util/useApi';
import { getTags } from '@/views/Home/api';
import { defineComponent, ref, watch, computed } from '@vue/composition-api';

export default defineComponent({
  setup(_, { root, emit }) {
    const keyword = ref('');
    const type = ref<'Patent' | 'Result'>('Patent');

    const main = useMainStore();
    main.init();
    const { result: tags, refresh: refreshTags } = useApi(() => getTags());

    watch(() => main.state.user, refreshTags);

    const search = () => {
      if (type.value) {
        root.$router.push({ name: type.value, query: { keyword: keyword.value } });
        emit('change');
      }
    };

    return {
      keyword,
      type,
      search,
      tags: computed(() => (tags.value ? tags.value.split(',') : ['口罩', '疫情防护'])),
    };
  },
});
