import { DirectiveOptions } from 'vue';

export const bottomDirective: DirectiveOptions = {
  update(el) {
    const wrapper = document.getElementById('content-warper') as HTMLDivElement;
    if (el && wrapper) {
      const elFrame = el.getBoundingClientRect();
      const wrapperFrame = wrapper.getBoundingClientRect();

      const elBottom = elFrame.bottom;
      const wrapperBottom = wrapperFrame.bottom;

      const elStyle = getComputedStyle(el);
      const elMarginTop = elStyle.marginTop ? Number(elStyle.marginTop.replace('px', '')) : 0;

      console.log(
        '----0----',
        wrapperBottom - elBottom,
        elMarginTop,
        wrapperBottom - elBottom > elMarginTop,
      );
      if (wrapperBottom - elBottom > 1 && wrapperBottom - elBottom > elMarginTop) {
        console.log('---1--');
        // el.style.marginTop = `${wrapperBottom - elBottom - elMarginTop - 20}px`;
      }
    }
  },
};
