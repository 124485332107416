






















import { useMainStore } from '@/store';
import { defineComponent, ref, watch, computed } from '@vue/composition-api';

export default defineComponent({
  setup(_, { root, emit }) {
    const main = useMainStore();

    const navTo = (id: string) => {
      if (root.$route.name !== 'Toolkit' || root.$route.params.id !== id) {
        root.$router.push({ name: 'Toolkit', params: { id } });
        emit('change');
      }
    };

    return { navTo, modules: computed(() => main.servicesModules) };
  },
});
